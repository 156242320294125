import { UserTravelState } from '../../components/Seasons/SeasonConstants';
import { IUserFlagModel } from '../User/UserFlagModel';
import { IUserComment } from '../User/UserListModel';

export interface IbookingsParticipantsList {
  amountPaid: number | null;
  batchStartDate: Date;
  bookingId: number;
  batchId: number;
  bookedOn: Date | null;
  bookingParticipantState: string;
  bookingState: string;
  cancelledOn: Date | null;
  createdAt: Date | null;
  dropOffLocation: string | null;
  dropOffStatus: UserTravelState | null;
  id: number;
  insuranceAmount: number | null;
  insuranceOpted: boolean;
  repeatTrekCount: number | null;
  participantComments: string | null;
  pendingAmount: number | null;
  pickupLocation: string | null;
  pickupStatus: UserTravelState | null;
  trekName: string;
  trekFeeForTheUser: number;
  taxPercentage: number;
  userDetailsForDisplay: IUserDetailsForParticipantListDisplay;
  userId: string;
  fullName?: string;
  fitnessDocumentExists: boolean;
  firstName: string;
  userReferenceId: string | number;
  lastName: string;
  height: string;
  weight: 0;
  bmi: 0;
  phone: string;
  email: string;
  dob: Date;
  gender: string;
  city: string;
  age: string;
  waitingListNumber: number;
  comment?: string;
  flag: number | undefined;
}

export interface IUserDetailsForParticipantListDisplay {
  id: 0;
  firstName: string;
  userReferenceId: string | number;
  lastName: string;
  height: string;
  weight: 0;
  bmi: 0;
  phone: string;
  email: string;
  dob: Date;
  gender: string;
  city: string;
  documentIdNumber: string;
  emergencyContactName: string;
  emergencyContactRelationshipToYou: string;
  emergencyContactEmail: string;
  emergencyContactNumber: string;
  comment: IUserDetailsComment;
  flag: IUserFlagModel;
  userProfiles: string[];
}

interface IUserDetailsComment extends IUserComment {
  createdOn: string;
  commentType: string;
  commentableId: number;
}

export interface IParticpantTrekLookupModel {
  trekName: string;
}

export enum BookingState {
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  INACTIVE = 'INACTIVE',
  DIFFICULT_TREK = 'DIFFICULT_TREK',
  WAITING_LIST = 'WAITING_LIST',
  INITIATED = 'INITIATED',
  PAYMENT = 'PAYMENT',
  ADD_PARTICIPANTS = 'ADD_PARTICIPANTS',
}

export enum BookingMethod {
  VOUCHER = 'VOUCHER',
  PAYMENT = 'PAYMENT',
}

export enum BookingParticipantState {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  WAITING_LIST = 'WAITING_LIST',
  FITNESS_REJECTED = 'FITNESS_REJECTED',
  NO_SHOW = 'NO_SHOW',
}

export enum FitnessState {
  APPROVED = 'APPROVED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  DOCUMENT_AVAILABLE = 'DOCUMENT_AVAILABLE',
  DOCUMENT_NOT_AVAILABLE = 'DOCUMENT_NOT_AVAILABLE',
  FITNESS_REJECTED = 'FITNESS_REJECTED',
}

export enum BookingPaymentState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export interface IParticipantsFilter {
  bookingState?: BookingState | undefined;
  bookingMethod?: BookingMethod | undefined;
  batchStartDateFrom?: string | undefined;
  batchStartDateTo?: string | undefined;
  gender?: string | undefined;
  email?: string | undefined;
  phoneNumber?: string;
  participantState?: BookingParticipantState | undefined;
  participantStatesList?: BookingParticipantState[] | undefined;
  trekId?: number | undefined;
  insuranceOpted?: boolean | undefined;
  bookedOnFrom?: string | undefined;
  bookedOnTo?: string | undefined;
  batchId?: number | undefined;
  userId?: number | undefined;
  bmiFrom?: number | undefined;
  bmiTo?: number | undefined;
  participantAgeFrom?: number | undefined;
  participantAgeTo?: number | undefined;
  fitnessDocumentsExists?: boolean | undefined;
  specialTrekType?: number | undefined;
  isPrivateBatch?: boolean | undefined;
  flag?: number | undefined;
}

export enum ParticipantBackpackOffloadingState {
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  INITIATED = 'INITIATED',
  UNDER_REVIEW = 'UNDER_REVIEW',
  COMPLETED = 'COMPLETED',
}
